
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { Select, Option, OptionGroup } from 'element-ui';
import { USER_LEVELS } from '@/modules/user/constants';
import StyledSearchbar from '@/modules/common/components/styled-searchbar.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import HomeFiltersService, { HomeFiltersServiceS } from '@/modules/home/home-filters.service';
import UserViewService, { UserViewServiceS } from '@/modules/user/user-view.service';
import HotelsService, { HotelsServiceS } from '../hotels.service';

@Component({
    components: {
        Select,
        Option,
        OptionGroup,
        StyledSearchbar,
    },
})
export default class HotelSelect extends Vue {
    @Inject(HotelsServiceS) hotelsService!: HotelsService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(HomeFiltersServiceS) homeFiltersService!: HomeFiltersService;
    @Inject(UserViewServiceS) private userViewService!: UserViewService;

    private clusters = [
        { name: this.userService.levelName!, value: `${this.userService.user.level}.${this.userService.levelName!}` },
    ];
    private searchQuery: string = '';

    get hotels() {
        if (this.isLoading && !this.hotelsService.myHotels) {
            return [];
        }

        return this.hotelsService.myHotels.map(h => ({
            name: h.name,
            value: `${USER_LEVELS.HOTEL}.${h.id}`,
        }));
    }

    get groups() {
        const filteredClusters = this.clusters.filter(c => new RegExp(this.searchQuery.toLowerCase()).test(c.name.toLowerCase()));
        const filteredHotels = this.hotels.filter(h => new RegExp(this.searchQuery.toLowerCase()).test(h.name.toLowerCase()));
        const result = [];

        if (filteredClusters.length) {
            result.push({
                label: this.$tc('titles.clusters'),
                options: filteredClusters,
            });
        }

        if (filteredHotels.length) {
            result.push({
                label: this.$tc('titles.hotels'),
                options: filteredHotels,
            });
        }
        return result;
    }

    get viewAs() {
        const { viewAs, currentHotelId, levelName } = this.userService;
        return `${viewAs}.${viewAs === USER_LEVELS.HOTEL ? currentHotelId : levelName}`;
    }

    set viewAs(levelAndId: string) {
        if (levelAndId === this.viewAs) {
            return;
        }

        const [level, id] = levelAndId.split('.');

        // [TODO] add USER_LEVEL.CHAIN condition as chain will be activated
        if (level === USER_LEVELS.CLUSTER) {
            this.userViewService.goToClusterPage(/* id */);
        } else if (level === USER_LEVELS.HOTEL) {
            this.userViewService.goToHotelPage(parseInt(id, 10));
        } else {
            throw new Error('Wrong user level');
        }
    }

    get isLoading() {
        return this.hotelsService.isUserHotelsLoading;
    }

    get isHotelUser() {
        return !this.userService.isChainOrClusterUser;
    }

    get hotelLevelName() {
        return this.userService.levelName;
    }

    afterType(query: string) {
        this.searchQuery = query || '';
    }
}
