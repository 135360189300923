
import { Vue, Component } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
// @ts-ignore
import AuthService, { AuthServiceS } from '@/modules/auth/auth.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import AlertComponent from '@/modules/alerts/components/alert-component.vue';
import ConfigService, { ConfigServiceS } from '@/modules/config/config.service';
import ProfileMenu, { IDropdownItem } from '@/modules/common/components/profile-menu.vue';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import WhatfixWidget from '@/modules/whatfix/components/whatfix-widget.vue';
import LocaleSwitcher from '@/modules/translations/locale-switcher.vue';
import HotelSelect from '@/modules/hotels/components/hotel-select.vue';
import Chocolate from './chocolate.vue';
import USER_LEVELS from '../../user/constants/user-levels.constant';
import BadRequestException from '../modules/exception-handler/exceptions/bad-request.exception';
import CiInfo from './ci-info.vue';

// NOTE: Temporary solution to enable locale switcher for specific emails
//       added by Osnat's request and will be removed soon.
const LOCALE_ENABLED_EMAILS: string[] = [
    'Admin_UIAPI_newdesign_The_Ascott@fornova.itsanes.com',
    'Millennium_chain_performance@sharklasers.com',
    'Pan_Pacific_test_chain@sharklasers.com',
    'dont_change_ascott_test@yopmail.com',
    'osnat@fornova.net',
    'christopher.tsang@pphg.com',
    'leow.leefong@parkroyalhotels.com',
    'sures.dhamodharan@parkroyalhotels.com',
    'victor.chen@panpacific.com',
    'yvonne.wang@panpacific.com',
    'amy.lembke@parkroyalhotels.com',
    'clara.lee@pphg.com',
    'yuky.li@pphg.com',
    'li.sihui@the-ascott.com',
    'melinda.yeoh@the-ascott.com',
    'Jessietjioe@clwebacc.com',
    'carrie.ho@the-ascott.com',
    'koh.chinsiong@the-ascott.com',
    'angela.li@the-ascott.com',
    'Cynthia.lim@the-ascott.com',
    'stepanus.adi@tauzia.com',
    'gloria.francis@the-ascott.com',
    'abdulmatin.samri@the-ascott.com',
    'jasmine.sim@the-ascott.com',
    'jessie.tjioe@the-ascott.com',
    'abdulmatin.samri@clwebacc.com',
    'germaine.bok@the-ascott.com',
    'eileen.choo@the-ascott.com',
    'michelle.choy@the-ascott.com',
    'gaius.cheah@the-ascott.com',
];

@Component({
    components: {
        AlertComponent,
        Chocolate,
        ProfileMenu,
        CiInfo,
        WhatfixWidget,
        LocaleSwitcher,
        HotelSelect,
    },
})
export default class Header extends Vue {
    @Inject(AuthServiceS) protected authService!: AuthService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(ConfigServiceS) configService!: ConfigService;
    @Inject(HotelsServiceS) protected hotelsService!: HotelsService;

    get isLocaleEnabled() {
        if (process.env.NODE_ENV === 'development' || window.location.hostname.startsWith('ci-dev.')) {
            return true;
        }
        return LOCALE_ENABLED_EMAILS.includes(this.userService.user.email || '');
    }

    get viewAsName(): string | null {
        const { currentHotelId, viewAs } = this.userService;

        if (!currentHotelId) {
            return null;
        }

        const isCluster = this.userService.isClusterUser && viewAs === USER_LEVELS.CLUSTER;
        const isChain = this.userService.isChainUser && viewAs === USER_LEVELS.CHAIN;

        if (isCluster || isChain) {
            return this.userService.levelName;
        }

        return this.hotelsService.getHotelName(currentHotelId);
    }

    get isNewUser() {
        return this.userService.isNewUser;
    }

    get isHotelView() {
        const isHotelPage = /\/hotel\//.test(this.$route.path);
        const isViewAsHotel = this.userService.viewAs === USER_LEVELS.HOTEL;

        if (this.userService.isHotelUser && (isHotelPage || isViewAsHotel)) {
            return true;
        }

        return false;
    }

    get dropdownItems(): IDropdownItem[] {
        const {
            isHotelUser,
            isCarUser,
            isNewUser,
        } = this.userService;

        let { hotelId } = this.$route.params;

        if (this.userService.viewAs !== USER_LEVELS.HOTEL) {
            hotelId = '';
        }

        return [
            {
                text: this.$tc('menu.settings'),
                link: { name: 'cluster.settings' },
                isVisible: (!isNewUser && isHotelUser) || false,
                divided: true,
            },
            {
                text: 'Cars Category Manager',
                link: 'cars-mapping',
                isVisible: isCarUser || false,
            },
            {
                text: this.$tc('menu.admin'),
                link: '',
                isVisible: this.userService.isAdmin,
                action: this.toAdmin,
            },
            {
                text: this.$tc('menu.logout'),
                link: '',
                isVisible: true,
                action: this.logout,
            },
        ];
    }

    get userName() {
        const { user } = this.userService.storeState;
        if (!user) {
            return null;
        }

        return `${user.firstName} ${user.lastName}`;
    }

    get userEmail() {
        const { user } = this.userService.storeState;
        if (!user) {
            return null;
        }

        return user.email;
    }

    get diAdminUrl() {
        return this.configService.diAdminUrl;
    }

    toAdmin() {
        if (!this.diAdminUrl) {
            throw new BadRequestException('No VUE_APP_DI_URL specified in the app.config.json!');
        }

        window.location.href = `${this.diAdminUrl}/login-token/${encodeURIComponent(localStorage.getItem('token') as string)}`;
    }

    logout() {
        this.authService.logout();
    }
}
