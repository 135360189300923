
import { Component, Vue } from 'vue-property-decorator';
import { Select, Option } from 'element-ui';
import { Inject } from 'inversify-props';
// @ts-ignore
import TranslationsService, { TranslationsServiceS } from './translations.service';
import TranslationsModel from './translations.model';
import Flag from '../common/components/ui-kit/flag.vue';

@Component({ components: { Flag, Select, Option } })
export default class LocaleSwitcher extends Vue {
    @Inject(TranslationsServiceS) private translationsService!: TranslationsService;

    get languages() {
        return this.translationsService.availableLocales.map(v => ({
            name: this.$t(`locale.${v}`),
            value: v,
        }));
    }

    get locale() {
        return this.$i18n.locale as keyof TranslationsModel;
    }

    set locale(newLocale: keyof TranslationsModel) {
        this.translationsService.setLocale(newLocale);
        this.translationsService.updateLocale(newLocale);
        // .then(() => this.$router.go(0));
    }

    get isLoading() {
        return this.translationsService.isLoading;
    }

    getFlagCode(locale: keyof TranslationsModel) {
        switch (locale) {
            case 'en':
                return 'gb';
            // [TODO] enable when jp translations will be approved
            // case 'ja':
            //     return 'jp';
            default:
                return locale;
        }
    }
}
