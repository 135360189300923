
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'inversify-props';

import CarAlertModel from '@/modules/cars/alerts/models/car.alert.model';
import ClipText from '@/modules/common/filters/clip-text.filter';
import CarsAlertsService, { CarsAlertsServiceS } from '../cars-alerts.service';

@Component({
    filters: { ClipText },
})
export default class CarsAlertItem extends Vue {
    @Inject(CarsAlertsServiceS) alertsService!: CarsAlertsService;

    @Prop({
        required: true,
        type: Object,
    })
    alert!: CarAlertModel;

    transformDate(date: Date): string {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return `${months[date.getMonth()]} ${date.getDate()}`;
    }

    get hasLink() {
        return this.alert.extData && this.alert.extData.link;
    }

    async handleViewButton() {
        const { path, params, sessionData } = this.alert.extData.link;
        sessionStorage.setItem('sessionData', JSON.stringify(sessionData));
        this.$router.push({
            name: path,
            params,
        });
        if (this.$parent && this.$parent.$parent && this.$parent.$parent.$refs.popover) {
            const popOverElement = this.$parent.$parent.$refs.popover as HTMLElement;
            popOverElement.style.visibility = 'hidden';
        }
    }

    get linkText() {
        const { text } = this.alert.extData.link;
        return text;
    }

    handleClick() {
        this.alertsService.markAlertAsRead(this.alert.id);
    }
}
