
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ConfigService, { ConfigServiceS } from '@/modules/config/config.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CustomPopover from '@/modules/common/components/ui-kit/custom-popover.vue';

@Component({
    components: {
        CustomPopover,
    },
})
export default class Chocolate extends Vue {
    @Inject(ConfigServiceS) private configService!: ConfigService;
    @Inject(UserServiceS) public userService!: UserService;

    active: boolean = false;

    setActive() {
        this.active = !this.active;
    }

    get activeStatus() {
        return this.active;
    }

    get diUrl(): string | null {
        return this.configService.diUrl;
    }

    get biUrl(): string | null {
        return this.configService.biUrl;
    }

    get ecUrl(): string | null {
        return this.configService.ecUrl;
    }
}
