
import { Vue, Component } from 'vue-property-decorator';
import AlertsService, { AlertsServiceS } from '@/modules/alerts/alerts.service';
import { Inject } from 'inversify-props';
import AlertReference from '@/modules/alerts/components/alert-reference.vue';
import AlertPopupContent from '@/modules/alerts/components/alert-popup-content.vue';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import OpenTelemetryService, { OpenTelemetryServiceS } from '@/modules/open-telemetry/open-telemetry.service';
import { LOGTYPE } from '@/modules/open-telemetry/constants';

@Component({
    components: {
        AlertReference,
        AlertPopupContent,
        LoaderWrapper,
    },
})
export default class AlertComponent extends Vue {
    @Inject(AlertsServiceS) private alertsService!: AlertsService;
    @Inject(UserServiceS) private userService!: UserService;
    @Inject(OpenTelemetryServiceS) private otelService!: OpenTelemetryService;

    isShown = false;

    get amountAlerts(): number {
        let { alerts } = this;

        const { hotelId } = this.$route.params;

        if (!this.$route.name) {
            return 0;
        }

        const isHotelLevel = this.$route.name.split('.').shift() === 'hotel';

        if (hotelId && isHotelLevel) {
            alerts = alerts.filter(a => a.fornovaId === +hotelId);
        }

        return alerts ? alerts.filter(a => !a.read).length : 0;
    }

    get alerts() {
        if (!this.$route.name) {
            return [];
        }

        const level = this.$route.name.split('.')[0].split('-')[0];

        if (level !== this.userService.viewAs) {
            return [];
        }

        const { alerts } = this.alertsService;

        return alerts || [];
    }

    public toggleAlerts() {
        this.isShown = !this.isShown;
        this.toggleListener();
        this.otelService.instantSpan({ name: 'alerts-toggle', prefix: LOGTYPE.CLICK }, { sendLogs: true });
    }

    private toggleListener() {
        if (this.isShown) {
            window.addEventListener('click', this.checkClickOutside);
            return;
        }

        window.removeEventListener('click', this.checkClickOutside);
    }

    private checkClickOutside(event: MouseEvent) {
        const { container } = this.$refs as { container: HTMLDivElement };
        const path = event.composedPath();

        if (path.includes(container)) return;
        this.toggleAlerts();
    }
}
